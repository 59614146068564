import React from "react";
import {  Route, Routes,Navigate } from "react-router-dom";
import HomeMgm ,{HomeMgmScreenName} from '../../pages/mgm/HomeMgm';
import MgmPrayersTimes  from '../../pages/mgm/MgmPrayersTimes';
import MgmMessages  from '../../pages/mgm/MgmMessages';
import MgmMemorys  from '../../pages/mgm/MgmMemorys';
import MgmLessons  from '../../pages/mgm/MgmLessons';
import MgmProfile  from '../../pages/mgm/MgmProfile';
import MgmHelp  from '../../pages/mgm/MgmHelp';

import About  from '../../pages/About';


export const MgmStackName = "/mgm";

export default function MgmStack() {

    return (
        <Routes>
            <Route path={HomeMgmScreenName} element={<HomeMgm/>} />
            <Route path="/mgmPrayersTimes" element={<MgmPrayersTimes/>} />
            <Route path="/mgmMessages" element={<MgmMessages/>} />
            <Route path="/mgmMemorys" element={<MgmMemorys/>} />
            <Route path="/mgmLessons" element={<MgmLessons/>} />
            <Route path="/profile" element={<MgmProfile/>} />
            <Route path="/help" element={<MgmHelp/>} />
            <Route path="/About" element={<About/>} />
            <Route path="/*" element={<Navigate to={HomeMgmScreenName} replace />} />

        </Routes>
    );
}