import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import BackButton from "../../componnents/shared/BackButton";
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";

export default function MgmHelp() {
    const navigate = useNavigate();
    const { synagogueData } = useContext(MgmSynagogueDataContext);
    const synagogueID = synagogueData?.synagogues?.id;

    return (
        <BaseContainer style={styles.container}>
            <BackButton title="עזרה" />
            <BaseContainer style={styles.cardContainer}>
                <h2 style={styles.title}>עמוד זה נמצא בפיתוח</h2>
                <p style={styles.text}>
                    אנו עובדים על שיפור המערכת והוספת אפשרויות נוספות.  
                    בינתיים, אם יש לך שאלות או שאתה זקוק לעזרה, תוכל ליצור קשר עם צוות התמיכה שלנו במייל:
                </p>
                <p style={styles.email}> <a href="mailto:tfilatovaweb@gmail.com" style={styles.link}>tfilatovaweb@gmail.com</a></p>
            </BaseContainer>
        </BaseContainer>
    );
}

const styles = {
    container: {
        width: "90%",
        margin: "20px auto",
    },
    cardContainer: {
        marginTop: "20px",
        textAlign: "center",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "8px",
       
    },
    title: {
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "10px",
    },
    text: {
        fontSize: "16px",
        color: "#333",
        marginBottom: "10px",
    },
    email: {
        fontSize: "16px",
        fontWeight: "bold",
    },
    link: {
        color: "#007bff",
        textDecoration: "none",
    },
};
