import axios from "axios";
import { selectMode } from "../environment";

const ALLOW_REQUESTS_LOGGING = true;
const ALLOW_RESPONSES_LOGGING = true;

const AUTHORIZATION_HEADER_NAME = "token";

export const STAGE_BASE_URL = "https://us-central1-digital-board-u.cloudfunctions.net/api/";
// export const STAGE_BASE_URL = "https://api.finscore-management.com";
export const PRODUCTION_BASE_URL = "https://us-central1-tfila-tova-prod.cloudfunctions.net/api/";
export const REALTIME_STAGE_BASE_URL = "https://digital-board-u-default-rtdb.firebaseio.com/";
export const REALTIME_PROD_BASE_URL = "https://tfila-tova-prod-default-rtdb.firebaseio.com/";
export const HEBCAL_URL = "https://www.hebcal.com/";

export const REALTIME_SCREEN_STAGE_BASE_URL = "https://tfila-tova-screen-default-rtdb.firebaseio.com/";
export const REALTIME_SCREEN_PROD_BASE_URL = "https://tfila-tova-screen-default-rtdb.firebaseio.com/";


const apiClient = axios.create({
    baseURL: selectMode(PRODUCTION_BASE_URL, STAGE_BASE_URL),
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
    },
    timeout: 50000,
});


// Set up a separate API client specifically for realTimeApi
const realTimeApiClient = axios.create({
    baseURL: selectMode(REALTIME_PROD_BASE_URL,REALTIME_STAGE_BASE_URL),
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
    },
    timeout: 50000,
});


const realTimeScreenApiClient = axios.create({
    baseURL: selectMode(REALTIME_SCREEN_PROD_BASE_URL,REALTIME_SCREEN_STAGE_BASE_URL),
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*'
    },
    timeout: 50000,
});


// Set up a separate API client specifically for realTimeApi
const hwbcalApiClient = axios.create({
    baseURL: HEBCAL_URL,
    headers: {

    },
    timeout: 50000,
});

export const ApiUtils =
{
    setAuthorizationHeader: (token) => {
        // apiClient.defaults.headers[AUTHORIZATION_HEADER_NAME] = token;
    },

    removeAuthorizationHeader: () => {
        // delete apiClient.defaults.headers[AUTHORIZATION_HEADER_NAME];
    },

    get: (endpoint, headers = {}) => {
        console.log("🚀 ~ endpoint:", endpoint)
        const { configurations, cancelRequest } = getRequestCancelationConfigurations();
        configurations.headers = { ...configurations.headers, ...headers };

        function startRequest() {
            console.log("🚀 ~ startRequest:")
            return handleRequest(() => apiClient.get(endpoint, configurations));
        }

        return { startRequest, cancelRequest };
    },


    post: (endpoint, data, config) => {
        const { configurations, cancelRequest } = getRequestCancelationConfigurations();

        const combinedConfigurations = {
            ...config,
            ...configurations,
        };

        function startRequest() {
            return handleRequest(() => apiClient.post(endpoint, data, combinedConfigurations))
        }

        return { startRequest, cancelRequest };
    },

    getRealTime: (endpoint, headers = {}) => {
        const { configurations, cancelRequest } = getRequestCancelationConfigurations();
        configurations.headers = { ...configurations.headers, ...headers };

        function startRequest() {
            return handleRequest(() => realTimeApiClient.get(endpoint, configurations));
        }

        return { startRequest, cancelRequest };
    },


    getScreenRealTime: (endpoint, headers = {}) => {
        const { configurations, cancelRequest } = getRequestCancelationConfigurations();
        configurations.headers = { ...configurations.headers, ...headers };

        function startRequest() {
            return handleRequest(() => realTimeScreenApiClient.get(endpoint, configurations));
        }

        return { startRequest, cancelRequest };
    },
    getHwbcal: (endpoint, headers = {}) => {
        const { configurations, cancelRequest } = getRequestCancelationConfigurations();
        configurations.headers = { ...configurations.headers, ...headers };
        function startRequest() {
            return handleRequest(() => hwbcalApiClient.get(endpoint, configurations));
        }

        return { startRequest, cancelRequest };
    },
}

function getRequestCancelationConfigurations() {
    const requestController = new AbortController();

    const configurations = {
        signal: requestController.signal,
    }

    function cancelRequest() {
        requestController.abort();
    }

    return {
        configurations,
        cancelRequest,
    };
}

async function handleRequest(requestFunction) {
    try {
        const response = await requestFunction();
        console.log("🚀 ~ handleRequest ~ response:", response)

        if (response.status == 200) {
            return {
                success: true,
                data: response.data,
            }
        }
        else {
            return {
                success: false,
                status: response.status,
                error: response.data,
            }
        }
    }
    catch (error) {
        console.log("🚀 ~ handleRequest ~ error:", error)
        return {
            success: false,
            error: error,
            status: error.response?.status,
        }
    }
}

apiClient.interceptors.request.use(request => {
    if (process.env.NODE_ENV && ALLOW_REQUESTS_LOGGING) {
        console.log('Starting Request', JSON.stringify(request, null, 2));
    }

    return request;
});

apiClient.interceptors.response.use(response => {
    if (process.env.NODE_ENV && ALLOW_RESPONSES_LOGGING) {
        console.log('Starting Response', JSON.stringify(response, null, 2));
    }

    return response;
});
