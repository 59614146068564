// configMap.js

export const configMap = {
    lessons: {
      fields: [
        {
          key: "title",
          label: "",
          style: { fontWeight: "600", color: "#333" },
        },
        {
          key: "value",
          label: "",
          style: { color: "#444" },
        },
        {
          key: "rabbi",
          label: "|",
          style: { color: "#444" },
        },
        {
          key: "location",
          label: "|",
          style: { color: "#444" },
        },
      ],
    },
    messages: {
      fields: [
        {
          key: "type",
          label: "",
          style: { fontSize: 'smaller',fontWeight: "600", padding: "0 3px",color: 'rgb(153 153 153 / 70%)',display:'block'},
        },
        {
          key: "value",
          label: "",
          style: { color: "#333" },
        },
       
      ],
    },
    about: {
      fields: [
        {
          key: "name",
          label: "",
          style: { fontWeight: "600", color: "#333" },
        },
        {
          key: "value",
          label: "",
          style: { color: "#666" },
        },
   
      ],
    },
    default: {
      fields: [
        {
          key: "name",
          label: "",
          style: { fontWeight: "600", color: "#333" },
        },
        {
          key: "value",
          label: "",
          style: { color: "#333" },
        },
        {
          key: "location",
          label: "",
          style: {color: "#666" },
        },
       
      ],
    },
  };
  