import { faTruckMedical } from "@fortawesome/free-solid-svg-icons";

export const cardPrayerConfig = {
  id: { label: "ID", type: "string", show: false, order: 3 },
  prayerCategoryID: { label: "Prayer Category ID", type: "string", show: false, order: 4 },
  categoryNameDisplay: { label: "שם לתצוגה", type: "string", show: false, order: 5 },
  name: { label: "שם תפילה", type: "string", show: true, placeholder: "הקלד שם תפילה", order: 1 },
  value: { label: "שעה", type: "string", show: true, placeholder: "הקלד שעת תפילה", order: 2 },
  location: { label: "מיקום/הערה", type: "string", show: true, placeholder: "הקלד מיקום או הערה", order: 6 },
  display: { label: "הצג", type: "boolean", show: true, inputType: "checkbox", order: 7 },
  index: { label: "Index", type: "number", show: false, order: 8 },
  lastUpdate: { label: "Last Update", type: "timestamp", show: false, order: 9 },
  prayerKeyID: { label: "Prayer Key ID", type: "string", show: false, order: 10 },
  synagogueID: { label: "Synagogue ID", type: "string", show: false, order: 11 },
  isHol: { label: "Is Holiday", type: "boolean", show: false, order: 12 },
};



export const cardMsgsConfig = {
  id: { label: "ID", type: "string", show: false  },
  value: { label: "הודעה", type: "string", show: faTruckMedical, inputType: "textarea", placeholder: "הקלד הודעה" , order: 2},
  index: { label: "Index", type: "number", show: false, disable: true },
  lastUpdate: { label: "Last Update", type: "timestamp", show: false },
  synagogueID: { label: "Synagogue ID", type: "string", show: false },
  type: {
    label: "סוג הודעה",
    type: "dropdown",
    show: true,
    inputType: "dropdown",
    options: [
      { id: "message", value: "הודעה" },
      { id: "mazal_tov", value: "מזל טוב" },
      { id: "memory", value: "כוס תנחומים" },
      { id: "sponser_week", value: "פרנס השבוע" },
      { id: "sponser_month", value: "פרנס החודש" },
      { id: "sponser_year", value: "פרנס השנה" }
    ], order: 1
    
  },
};

export const cardMemoriesConfig = {
  name: { label: "שם נפטר", type: "string", show: faTruckMedical, inputType: "text", placeholder: "הקלד שם נפטר" },
  id: { label: "ID", type: "string", show: false },
  index: { label: "Index", type: "number", show: false, disable: true },
  englishDate: {
    label: "תאריך",
    type: "timestamp",
    show: true,
    inputType: "hebrewDate", 
    placeholder: "בחר תאריך",
  },

};


export const cardSynagogueConfig = {
  id: { label: "ID", type: "string", show: false },
  synagogueName: { label: "שם בית כנסת", type: "string", show: true, placeholder: "הקלד שם בית כנסת" },
  displayName: { label: "שם בית הכנסת לתצוגה", type: "string", show: true, placeholder: "הקלד שם בית כנסת לתצוגה" },
  email: { label: "מייל", type: "string", show: true,disable:true, placeholder: "הקלד מייל" },
  phone: { label: "מס פלאפון", type: "string", show: true,placeholder:"מס פלאפון ליצירת קשר" },
  password: { label: "Password", type: "string", show: false },
  userName: { label: "שם גבאי", type: "string", show: true },
  rabbi: { label: "שם רב בית הכנסת", type: "string", show: true },
  type: {
    label: "נוסח תפילה",
    type: "dropdown",
    show: true,
    inputType: "dropdown",
    options: ['ע"מ',"ספרד" ,"אשכנז", "תימני", "מרוקאי", "לפי החזן",],
  },
  country: { label: "ארץ", type: "string", show: true, placeholder: "הקלד ארץ" },
  city: { label: "עיר", type: "string", show: true, placeholder: "הקלד עיר" },
  street: { label: "רחוב", type: "string", show: true, placeholder: "הקלד רחוב" },
  buildingNumber: { label: "מספר בית", type: "string", show: true, placeholder: "הקלד מספר בית" },
  geoLocation: { label: "מיקום במפה", type: "array", show: false },
  geonameID: { label: "מיקום במפה", type: "string", show: false },
  lastUpdate: { label: "Last Update", type: "timestamp", show: false },
};


export const cardLessonsConfig = {
  id: { label: "ID", type: "string", show: false },
  title: { label: "נושא השיעור", type: "string", placeholder: "הקלד את נושא השיעור",show: true, order: 1  },
  rabbi: { label: "מוסר השיעור", type: "string", show: true,placeholder:"הקלד שם מוסר השיעור", order: 2  },
  value: { label: "יום ושעה", type: "string", show: true, placeholder: "לדוג' א-ה בשעה 20:00", order: 3  },
  location: { label: "מיקום", type: "string", show: true, placeholder: "הקלד מיקום", order: 4  },
  index: { label: "Index", type: "number", show: false },
  synagogueID: { label: "Synagogue ID", type: "string", show: false },
};