import React, { useContext, useState, useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import BackButton from "../../componnents/shared/BackButton";
import EditCards from "../../componnents/shared/EditCards";
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { cardSynagogueConfig } from "../../data/config";
import useHttpRequest from '../../hooks/useHttpRequest';
import { mgmApi } from '../../api/apiCalls';

export default function MgmProfile() {
    const navigate = useNavigate();
    const { synagogueData } = useContext(MgmSynagogueDataContext);
    const synagogueID = synagogueData?.synagogues?.id;
    const [filteredData, setFilteredData] = useState([]);
    const [newCard, setNewCard] = useState(null);
    const { performRequest: deleteSynagogue } = useHttpRequest(mgmApi.deleteSynagogue);



    const headers = ["hebrewName"];


     useEffect(() => {
            
            setFilteredData([synagogueData.synagogues]);
        }, [synagogueData]);

    const handleDeleteSynagogue = async () => {
        const result = await Swal.fire({
            title: "האם אתה בטוח?",
            text: "לא תוכל לשחזר את בית הכנסת לאחר מחיקה זו!",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "כן, מחק!",
            cancelButtonText: "בטל",
        });

        if (result.isConfirmed) {
         

            // deleteSynagogue(synagogueID)
            //     .then(() => {
            //         Swal.fire("נמחק!", "בית הכנסת נמחק בהצלחה.", "success");
            //     })
            //     .catch((error) => {
            //         console.error("Error deleting data:", error);
            //         Swal.fire({
            //             title: "שגיאה במחיקה",
            //             icon: "error",
            //             confirmButtonText: "OK",
            //         });
            //     });

                navigate("/");
        }
    };

    return (
        <BaseContainer style={styles.container}>
            <BackButton title="פרופיל" />
            <BaseContainer style={styles.cardContainer}>
                <EditCards
                    cardData={filteredData}
                    headerFields={headers}
                    newCard={newCard}
                    collectionName="synagogues"
                    cardConfig={cardSynagogueConfig}
                    synagogueID={synagogueID}
                    showAddAndRemoveButton={false}
                    openAlways={true}
                />
            </BaseContainer>
            <button style={styles.deleteButton} onClick={handleDeleteSynagogue}>
                מחיקת בית הכנסת
            </button>
        </BaseContainer>
    );
}

const styles = {
    container: {
        width: "90%",
        margin: "20px auto",
    },
    cardContainer: {
        marginTop: "20px",
    },
    deleteButton: {
        backgroundColor: "rgb(221, 51, 51)",
        color: "rgb(255, 255, 255)",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        fontSize: "12px",
        padding: "5px 10px",
        marginTop: "20px",
        float: "right",
    },
};
