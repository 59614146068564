import React, { useEffect,useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseContainer } from "../componnents/baseTags/BaseContainer"
import { BaseButton } from "../componnents/baseTags/BaseButton"
import { LoginStackName } from '../navigation/login/LoginStack'
import Header from "../componnents/shared/Header";
import CustomSelect from '../componnents/shared/CustomSelect';
import useAutoHttpRequest from '../hooks/useAutoHttpRequest';
import { realTimeScreenApi } from '../api/apiCalls';

export const HomeScreenName = "/";

export default function Home() {
    const { result: nameResult } = useAutoHttpRequest(realTimeScreenApi.getSynagogueNames);
    const [options, setOptions] = useState([]);

    const navigate = useNavigate();

    const getGreeting = () => {
        const hour = new Date().getHours();
        if (hour < 12) return "בוקר טוב";
        else if (hour < 18) return "צהריים טובים";
        else return "ערב טוב";
    };

    function onValueChange(selectedOption) {
        console.log('Selected option:', selectedOption);
        navigate(`/user-dashboard/${selectedOption.value}`);
    }


    function HandleLoginPress() {
        navigate(LoginStackName)
    }

    useEffect(() => {
        if (nameResult) {
            console.log("🚀 ~ Home ~ result:", nameResult.data)
            setOptions(nameResult.data)
        }
    }, [nameResult]);
    const [currentTime, setCurrentTime] = useState("");
    useEffect(() => {
        // לעדכן את הזמן כשמטעינים את הקומפוננטה
        setCurrentTime(new Date().getTime());
      }, []);

    return (
        <BaseContainer>
            <Header />

            <BaseContainer style={containerDadStyle}>
                <BaseContainer style={containerStyle}>
                    <BaseContainer >
                        <h5 style={headerStyle}>{getGreeting()}</h5>
                        <h6 style={headerStyle}>"ברוך הבא ל"תפילה טובה</h6>
                        <h6 style={headerStyle}>
                            באפליקציה זו תוכל לחפש בתי כנסת באזורך, לבדוק את זמני התפילות שלהם,
                            ולבחור את הזמן המתאים ביותר עבורך להתפלל
                        </h6>
                        <h6 style={headerStyle}>
                        לפרטים <a href={`https://lp.tfilatova.com?reload=${currentTime}`} target="_blank" rel="noopener noreferrer">לחץ כאן</a>                        </h6>

                        <BaseButton style={btnStyle} onPress={HandleLoginPress}>
                            גבאי? התחבר / הרשם
                        </BaseButton>

                        <BaseContainer style={iconHeaderStyle}>

                            {/* <FontAwesomeIcon icon={faBookTanakh} /> */}
                        </BaseContainer>

                    </BaseContainer>
                </BaseContainer>


                <BaseContainer style={CustomSelectContainer} >

                    <CustomSelect onChange={onValueChange} options={options} placeholder="לצפייה בזמנים הקלד שם בית כנסת" />

                </BaseContainer>

            </BaseContainer>

            {/* <MgmFooter /> */}
        </BaseContainer>

    )

}


const btnStyle = {
    background: 'rgb(0 0 0 / 4%)',
    color: 'white',
    margin: '15px 0px',
    padding: '5px 15px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease' // אנימציה חלקה
};

const headerStyle = {
    // fontSize: '24px',
    // fontWeight: 'bold',
    color: '#2C3E50'
};


const CustomSelectContainer = {
    marginTop: '25px',
    direction: 'rtl'
}

const iconHeaderStyle = {
    fontSize: '55px',
    color: 'rgb(42 67 101)',
    textAlign: 'left'
};
const containerDadStyle = {
    paddingBottom: '25px',
    margin: '20px 5%'

};
const containerStyle = {
    backgroundColor: 'rgb(166 202 236)',
    padding: '0',
    margin: '0',
    borderRadius: '6px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    height: '31vh',
    textAlign: 'right',
    minHeight: '200px',
    padding: '11px'
};
