import React from "react";
import {  Route, Routes ,Navigate} from "react-router-dom";
import Home,{HomeScreenName}  from '../../pages/Home';


export const MainStackName = "/home";





export default function MainStack() {

    return (
        <Routes>
            <Route path={HomeScreenName} element={<Home/>} />
            <Route path="/*" element={<Navigate to={HomeScreenName} replace />} />

        </Routes>
    );
}