import React, { createContext, useState, useCallback, useEffect, useMemo } from 'react';
import useHttpRequest from '../hooks/useHttpRequest';
import { realTimeApi, loginApi, mgmApi } from '../api/apiCalls';
import isEqual from 'lodash/isEqual';

// Create the context
export const MgmSynagogueDataContext = createContext();

// Provider component
const MgmSynagogueDataProvider = ({ children }) => {
  const [synagogueData, setSynagogueData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hasChanges, setHasChanges] = useState(false);


  const { result: getDataResult, performRequest: getSynagogueAllDataByPass } = useHttpRequest(loginApi.getSynagogueAllDataByPass);
  const { result: getUpdatedRecordsResult, performRequest: getUpdatedRecords } = useHttpRequest(mgmApi.getUpdatedRecords);



  const fetchData = useCallback(async (email, password) => {
    try {
      setIsLoading(true);
      const result = await getSynagogueAllDataByPass(email, password);
      if (result && !isEqual(synagogueData, result)) {
        const updatedData = {
          ...result
        };
        setSynagogueData(updatedData);
      }
    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, [getSynagogueAllDataByPass, synagogueData]);


  const UpdateContext = useCallback(async (synagogueID, newOutdatedFields) => {
    try {
      setIsLoading(true);
      getUpdatedRecords(synagogueID, newOutdatedFields);
      
    } catch (err) {
      setError('Failed to fetch data');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const updateDocumentsByKeysInContext = useCallback((data) => {
    console.log("🚀 ~ ********************8")
    console.log("🚀 ~ updateDocumentsByKeysInContext ~ data:", data)
    if (!synagogueData) return;

    const updatedData = { ...synagogueData };
    console.log("🚀 ~ updateDocumentsByKeysInContext ~ updatedData:", updatedData)

    Object.keys(data).forEach((key) => {
      const { data: newDocuments, isDeleted } = data[key];

      if (isDeleted) {
        const prayerCategoryIDs = new Set(newDocuments.map((doc) => doc.prayerCategoryID));
        const filteredDocs = (updatedData[key] || []).filter(
          (doc) => !prayerCategoryIDs.has(doc.prayerCategoryID)
        );
      
        updatedData[key] = newDocuments.length ? [...filteredDocs, ...newDocuments] : [];
      } else if (Array.isArray(newDocuments)) {
        const existingDocuments = updatedData[key] || [];
        const newDocumentsMap = new Map(newDocuments.map((doc) => [doc.id, doc]));
        const mergedDocuments = existingDocuments.map((doc) =>
          newDocumentsMap.has(doc.id) ? { ...doc, ...newDocumentsMap.get(doc.id) } : doc
        );
        newDocuments.forEach((newDoc) => {
          if (!existingDocuments.some((doc) => doc.id === newDoc.id)) {
            mergedDocuments.push(newDoc);
          }
        });
        updatedData[key] = mergedDocuments;
      } else {
        updatedData[key] = newDocuments || [];
      }
    });
    console.log("🚀 ~ updateDocumentsByKeysInContext ~ updatedData:", updatedData)

    setSynagogueData(updatedData);
  }, [synagogueData]);



  useEffect(() => {
    if (getUpdatedRecordsResult?.success) {
      console.log('🚀 ~ useEffect ~ getUpdatedRecordsResult?.success:', getUpdatedRecordsResult);
      updateDocumentsByKeysInContext(getUpdatedRecordsResult.data);
    }
  }, [getUpdatedRecordsResult]);

  useEffect(() => {
    if (getDataResult) {
      console.log('🚀 ~ useEffect ~ getDataResult:', getDataResult);
      if (getDataResult.success) {
        setError('');
        setSynagogueData(getDataResult.data);
      } else {
        setError('Error fetching initial data');
      }
    }
  }, [getDataResult]);

  // Memoized context value
  const contextValue = useMemo(() => ({
    synagogueData,
    isLoading,
    error,
    setIsLoading,
    setError,
    fetchData,
    UpdateContext,
    updateDocumentsByKeysInContext,
    hasChanges, setHasChanges
  }), [synagogueData, isLoading, error, fetchData,UpdateContext, updateDocumentsByKeysInContext]);


  const finalContextValue = {
    ...contextValue,
    hasChanges,
    setHasChanges
  };

  return (
    <MgmSynagogueDataContext.Provider value={finalContextValue}>
      {children}
    </MgmSynagogueDataContext.Provider>
  );
};

export default MgmSynagogueDataProvider;
