import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";
import MgmSynagogueDataProvider from './provider/mgmSynagogueDataProvider';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <MgmSynagogueDataProvider>
           <App />
      </MgmSynagogueDataProvider>

  </BrowserRouter>,
    
);

reportWebVitals();