// BaseCard.jsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { configMap } from "../../data/userDashboardConfig";

const UserCard = ({
  row,
  collectionName,
  borderRight,
  icon,
  iconLeft,
  onPress,
  customStyles = {},
  footer,
}) => {
  // נשלוף את הקונפיג המתאים מתוך map 
  const collectionConfig = configMap[collectionName] || configMap["default"];
  if (!collectionConfig) {
    // אם אין קונפיג - לא מציגים כלום או אפשר להציג הודעה כלשהי
    return null;
  }

  return (
    <div
      onClick={onPress}
      style={{ ...styles.cardContainer, borderRight, ...customStyles.cardContainer }}
    >
      {/* כותרת הכרטיס (אופציונלי)
      <div style={styles.cardHeader}>
        <div style={styles.titleContainer}>
          {icon && <FontAwesomeIcon icon={icon} style={styles.icon} />}
          {iconLeft && <FontAwesomeIcon icon={iconLeft} style={styles.iconLeft} />}
        </div>
      </div> */}

      {/* יצירת השדות ע"פ מה שמוגדר בקונפיג */}
      <div style={styles.fieldsContainer}>
        {collectionConfig.fields.map((field, index) => {
          const fieldValue = row[field.key] || "";
          return (
            fieldValue && (
              <>
                <span style={field.style}>{field.label}</span>
                <span style={{ ...styles.field, ...field.style }}>{fieldValue}</span>
              </>
            )
          );
        })}
      </div>

      {/* footer */}
      {/* {footer && <div style={styles.cardFooter}>{footer}</div>} */}
    </div>
  );
};

export default UserCard;

// סגנונות בסיסיים (אפשר להתאים לפי צורך)
const styles = {
  cardContainer: {
    width: '100%',
    margin: '10px auto',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: 3,
    background: 'white',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    cursor: 'pointer',
    flexDirection: 'row-reverse',
    background: 'white',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',

  },
  field: {
    padding: 3,
    textAlign: 'rtl',
    direction: 'rtl'
  },
  icon: {
    marginLeft: "8px",
    backgroundColor: "#8eb8d83b",
    padding: "5px",
    borderRadius: "55px",
    width: "20px",
    height: "20px",
    color: "#343a40f0",
  },
  iconLeft: {
    marginRight: "9px",
    padding: "5px",
    borderRadius: "5px",
    width: "15px",
    height: "15px",
  },
  fieldsContainer: {
    marginTop: 10,
    margin: 0,
    fontSize: '1em',
    padding: 10,
    textAlign: 'rtl',
     direction: 'rtl'
  },

  cardFooter: {
    fontSize: "0.875em",
    color: "#555",
    marginTop: "10px",
  },
};
