import React, { useContext, useState } from 'react';
import { MgmSynagogueDataContext } from "../../provider/mgmSynagogueDataProvider";
import { images } from "../../styles/images";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { TermsStackName } from '../../pages/TermsAndConditions'
import { AboutName } from '../../pages/About'

import { useNavigate } from "react-router-dom";

export default function Header({ showDropdown = true }) {
    const navigate = useNavigate();

    const { synagogueData } = useContext(MgmSynagogueDataContext);
    const [menuOpen, setMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleOptionClick = (option) => {
        setMenuOpen(false);
        switch (option) {
            case 'feedback':
                window.location.href = 'mailto:tfilatovaweb@gmail.com?subject=משוב%20עבור%20תפילה%20טובה&body=שלום,%0A%0Aאני רוצה לתת משוב על...';
                break;
            case 'landing':
                window.location.href = '/landing';
                break;
            case 'about':
                navigate(AboutName);
                break;
            case 'terms':
                navigate(TermsStackName);
                break;
            default:
                break;
        }
    };

    return (
        <div style={headerStyle} className="d-flex align-items-center justify-content-between p-1 shadow-sm sticky-top">
            <div className='p-3'>
                {showDropdown && (
                    <FontAwesomeIcon
                        icon={faEllipsisV}
                        style={{ cursor: 'pointer', fontSize: '20px' }}
                        onClick={handleMenuToggle}
                    />
                )}
                {menuOpen && (
                    <>
                        <div
                            onClick={() => setMenuOpen(false)}
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 900, // נמוך יותר מהתפריט
                                backgroundColor: 'transparent'
                            }}
                        />
                        <ul
                            className="dropdown-menu show"
                            style={{
                                position: 'absolute',
                                top: '50px',
                                left: '10px',
                                zIndex: 1000,
                                borderRadius: '3px', // Reduce border radius
                                border: '1px solid #eaeaea',
                            }}
                        >
                            <li>
                                <button
                                    className="dropdown-item"
                                    onClick={() => handleOptionClick('feedback')}
                                >
                                    שלח משוב
                                </button>
                            </li>
                            {/* <li><hr className="dropdown-divider" /></li>
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleOptionClick('landing')}
                        >
                            Landing Page
                        </button>
                    </li> */}
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <button
                                    className="dropdown-item"
                                    onClick={() => handleOptionClick('about')}
                                >
                                    אודות
                                </button>
                            </li>
                            <li><hr className="dropdown-divider" /></li>
                            <li>
                                <button
                                    className="dropdown-item"
                                    onClick={() => handleOptionClick('terms')}
                                >
                                    תנאים והגבלות
                                </button>
                            </li>
                        </ul>
                    </>
                )}
            </div>
            <img
                src={images.Logos.logo}
                alt="TfilaTova"
                width="110"
                className="mx-auto"
            />
        </div>
    );
}
const headerStyle = {
    background: 'white'
};