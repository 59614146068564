import React, { useEffect, useCallback, useState } from "react";
import { useParams } from 'react-router-dom';
import { BaseContainer } from "../../componnents/baseTags/BaseContainer";
import { userApi } from '../../api/apiCalls';
import useHttpRequest from '../../hooks/useHttpRequest';
import HorizontalNavScroll from "../../componnents/baseTags/HorizontalNavScroll";
import UserCard from "../../componnents/shared/UserCard";
import BackButton from '../../componnents/shared/BackButton';
import Loader from '../../componnents/shared/Loader';



export default function UserDashboard() {
    const [filteredData, setFilteredData] = useState([]);
    const [collectionName, setCollectionName] = useState("");
    const [synagogueName, setSynagogueName] = useState("");
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const { result: getDataResult, performRequest: getSynagogueDataByID } = useHttpRequest(userApi.getSynagogueDataByID);
    const { id } = useParams();
    
    
    const staticCategories = [
        { id: "lessons", value: "שיעורים" },
        { id: "messages", value: "הודעות" },
        { id: "about", value: "אודות" },
      ];

    useEffect(() => {
        if(getDataResult){
            const dynamicCategories = getDataResult.data.prayerCategories.filter(x=>x.display).sort((a, b) => a.index - b.index) || [];
            const merged = [ ...dynamicCategories,...staticCategories];
            setCategories(merged);
            setSynagogueName(getDataResult?.data?.synagogue?.synagogueName)
        }
    }, [getDataResult]);


 

    useEffect(() => {
        setLoading(true);
        getSynagogueDataByID(id)
            .finally(() => setLoading(false));
    }, [id, getSynagogueDataByID]);



    const handleCategoryClick = useCallback(
        (categoryId) => {
          setCollectionName(categoryId);
    
          if (categoryId === "lessons") {
            const lessons = getDataResult?.data?.lessons || [];
            setFilteredData(lessons);
          } else if (categoryId === "messages") {
            const synagogueMessages = getDataResult?.data?.synagogueMessages || [];
            setFilteredData(synagogueMessages);
          } else if (categoryId === "about") {
            const synagogue = [
                { id: "1", name: "נוסח התפילה", value: getDataResult?.data?.synagogue?.type || "לא זמין" },
                { id: "2", name: "רב בית הכנסת", value: getDataResult?.data?.synagogue?.rabbi || "לא זמין" },
                { id: "3", name: "שם הגבאי", value: getDataResult?.data?.synagogue?.userName || "לא זמין" },
                { id: "4", name: "רחוב", value: getDataResult?.data?.synagogue?.street || "לא זמין" }
            ];
            setFilteredData(synagogue);
          } else {
          
            const prayerData = getDataResult?.data?.prayerTimes
                .filter(prayer => prayer.prayerCategoryID === categoryId)
                .sort((a, b) => a.index - b.index);

            setFilteredData(prayerData);
          }
        },
        [ getDataResult]
      );
  
      useEffect(() => {
        if (categories.length > 0) {
          handleCategoryClick(categories[0].id);
        }
      }, [categories, handleCategoryClick]);
  
      if (loading) {
        return <Loader />;
      }

      
    return (
        <BaseContainer style={styles.container}>
        <BackButton title={synagogueName} />
        <HorizontalNavScroll
          items={categories.map((cat) => ({
            value: cat.value, // assuming 'value' is the name you want to display
            id: cat.id,
          }))}
          onItemClick={handleCategoryClick}
        />
         {filteredData.length > 0 ? (
        filteredData.map((row) => (
          <UserCard key={row.id} row={row} collectionName={collectionName} />
        ))
      ) : (
        <p style={styles.noDataMessage}>אין נתונים להצגה</p>
      )}
      </BaseContainer>
    );
}

const styles = {
    container: {
        width: '90%',
        margin: '20px auto',
    },
    cardContainer: {
        marginTop: '20px',
    },
    noDataMessage: {
      textAlign: "center",
      marginTop: "20px",
      fontSize: "16px",
      color: "#888",
    },
};
