import React, { useState ,useEffect,useContext} from "react";
import Swal from "sweetalert2";
import { MgmSynagogueDataContext } from '../../provider/mgmSynagogueDataProvider';

const HorizontalNavScroll = ({ items, onItemClick }) => {
 
  const [selectedItem, setSelectedItem] = useState(null);
      const {   hasChanges,setHasChanges } = useContext(MgmSynagogueDataContext);

  // כאשר items מתעדכן ואינו ריק, נבחר כברירת מחדל את הפריט הראשון
  useEffect(() => {
    if (items && items.length > 0 && selectedItem === null) {
      setSelectedItem(items[0].id);
      onItemClick(items[0].id); // מפעילים את הפונקציה כדי להתריע להורה
    }
  }, [items, selectedItem, onItemClick]);

  const handleClick = (item) => {
    // אם יש שינויים שלא נשמרו, הצג פופאפ
    if (hasChanges) {
      Swal.fire({
        title: 'יש שינויים שלא נשמרו',
        text: '?האם אתה בטוח שברצונך לצאת מבלי לשמור',
        showCancelButton: true,
        confirmButtonText: 'כן, צא',
        cancelButtonText: 'לא, חזור לעריכה',
        focusCancel: true,   
        customClass: {
         cancelButton: 'swal-cancel-button', // מחלקה מותאמת לכפתור הביטול
         confirmButton: 'swal-confirm-button' // מחלקה מותאמת לכפתור האישור
       }
      }).then((result) => {
        if (result.isConfirmed) {
          setHasChanges(false)
          setSelectedItem(item.id);
          onItemClick(item.id);  // מפעיל את הפונקציה על מנת לעבור לכרטיס החדש
        }
      });
    } else {
      setSelectedItem(item.id);
      onItemClick(item.id);
    }
  }

  return (
    <div style={styles.horizontalNavScroll}>
      <div style={styles.navItems}>
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              ...styles.navItem,
              borderBottom:
                selectedItem === item.id ? styles.navItemSelected.borderBottom : 'none',
              backgroundColor: selectedItem === item.id
                ? styles.navItemSelected.backgroundColor
                : styles.navItem.backgroundColor,
              fontWeight: selectedItem === item.id ? styles.navItemSelected.fontWeight : 'normal',
            }}
            onClick={() => handleClick(item)} // Update selectedItem when clicked
            onMouseEnter={(e) => (e.target.style.backgroundColor = styles.navItemHover.backgroundColor)}
            onMouseLeave={(e) =>
              (e.target.style.backgroundColor =
                selectedItem === item.id
                  ? styles.navItemSelected.backgroundColor
                  : styles.navItem.backgroundColor)
            }
          >
            {item.value} {/* Display the value */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HorizontalNavScroll;

// Styles as JSON
const styles = {
  horizontalNavScroll: {
    display: 'flex',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    scrollbarColor: 'rgba(0, 0, 0, 0.2) transparent',
    flexDirection: 'row-reverse',
    boxShadow: 'rgba(17, 17, 26, 0.1) 0px 1px 0px',
    backgroundColor:'white'
  },
  navItems: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'row-reverse'
  },
  navItem: {
    padding: '0.7rem',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'border-bottom-color 0.3s ease', // Smooth transition for border color
  },
  navItemHover: {
  },
  navItemSelected: {
    borderBottom: '2px solid #0d6efda6',
    fontWeight: '600'
  },
  mediaMaxWidth768: {
    horizontalNavScroll: {
      padding: '0.7rem 0.5rem'
    },
    navItem: {
      padding: '0.5rem',
    },
  },
};
