import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faChevronUp, faChevronDown, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ReactJewishDatePicker } from 'react-jewish-datepicker';
import 'react-jewish-datepicker/dist/index.css';
const EditCard = ({
  item,
  isOpen,
  toggleCard,
  onChange,
  onDelete,
  headerFields,
  cardConfig,
  showAddAndRemoveButton = true,
  openAlways = false,
  disabled = true
}) => {
  // פונקציה לעיצוב טיימסטמפ, כפי שהיה
  const formatTimestamp = (timestamp) => {
    if (timestamp && typeof timestamp === 'object' && '_seconds' in timestamp) {
      const date = new Date(timestamp._seconds * 1000);
      return date.toLocaleString();
    }
    return timestamp;
  };

  // סינון ומיון של השדות לפי 'order'
  const sortedFields = Object.keys(cardConfig)
    .filter((key) => cardConfig[key].show)
    .sort((a, b) => (cardConfig[a].order || 0) - (cardConfig[b].order || 0));

  return (
    <div style={{ ...styles.cardContainer, ...(item.display === false || !disabled ? styles.displayCard : {}) }}>
      <div style={styles.cardHeader} onClick={() => !openAlways && toggleCard(item.id)}>
        <div style={styles.containerHeader}>
          <div style={styles.cardTitle}>
            {headerFields.map((field) => (
              <span key={field} style={styles.marginLeft5}>
                {item[field] ? item[field] : ''}
                {field === 'index' ? '.' : ''}
              </span>
            ))}
          </div>
          <FontAwesomeIcon icon={faGripVertical} style={{ ...styles.gripDotsContainerIcon, ...styles.marginLeft10 }} />
        </div>
        {!openAlways &&
          (isOpen ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />)}
      </div>
      <div style={styles.cardContent(openAlways || isOpen)}>
        {sortedFields.map((key) => {
          const config = cardConfig[key];
          const label = config.label || key;
          let value = item[key];

          // If the value is a timestamp, format it.
          if (typeof value === 'object' && value !== null && '_seconds' in value) {
            value = formatTimestamp(value);
          }


          return (
            <div style={styles.cardList} key={key}>
              {config.inputType === 'checkbox' ? (
                <div style={styles.inputCheckbox} className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={value}
                    onChange={(e) => onChange(item.id, key, e.target.checked)}
                    onClick={(e) => e.stopPropagation()}
                    disabled={config.disable}
                  />
                </div>
              ) : config.inputType === 'textarea' ? (
                <textarea
                  style={styles.textareaField}
                  value={value}
                  placeholder={config.placeholder}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                />
              ) : config.inputType === 'dropdown' ? (
                <select
                  style={styles.dropdownField}
                  value={value}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                >
                  {config.options.map((option, index) => (
                    <option key={index} value={option.id || option}>
                      {option.value || option}
                    </option>
                  ))}
                </select>
              ): config.inputType === 'hebrewDate' ? (
                <div style={styles.datepickerField}>

                  {(() => {
                    const parsedDate = value && !(value instanceof Date) ? new Date(value) : value;

                    var now = new Date()

                    return (
                      <ReactJewishDatePicker
                        style={{ left: '0px' }}
                        value={parsedDate || now}
                        isHebrew
                        onClick={(day) => {
                          onChange(item.id, key, {
                            gregorianDate: day.date,
                            hebrewDate: {
                              day: day.jewishDate.day,
                              month: day.jewishDate.month,
                              year: day.jewishDate.year
                            },
                            jewishDateStrHebrew: day.jewishDateStrHebrew

                          });
                        }}
                      />
                    );
                  })()}
                </div>
              ) 
              : (
                <input
                  style={styles.inputField}
                  type="text"
                  value={value}
                  placeholder={config.placeholder}
                  onChange={(e) => onChange(item.id, key, e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  disabled={config.disable}
                />
              )}
              <label style={styles.label}>:{label}</label>
            </div>
          );
        })}
        {showAddAndRemoveButton && (
          <div style={styles.deleteIcon}>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => {
                e.stopPropagation(); // מונע פתיחה/סגירה בעת לחיצה על המחיקה
                onDelete(item.id);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditCard;


const styles = {
  label: {
    textAlign: 'right'
  },
  cardContainer: {
    width: '100%',
    margin: '7px auto',
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: 5,
    background: 'white',
  },
  displayCard: {
    backgroundColor: '#f0f0f0bf',
    color: '#b0b0b0bd',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    cursor: 'pointer',
    flexDirection: 'row-reverse',
  },
  cardTitle: {
    margin: 0,
    fontSize: '1.2em',
    textAlign: 'rtl',
    maxWidth: '70vw',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    direction: 'rtl'
  },
  deleteIcon: {
    color: '#e74c3c',
    cursor: 'pointer',
    maxWidth: '10px',
    textAlign: 'center',
    marginTop: '15px'
  },
  dropdownField: {
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    border: 'none',
    borderRadius: '4px',
    direction: 'rtl',
    background: 'white',
  },
  cardContent: (isOpen) => ({
    padding: '15px',
    display: isOpen ? 'block' : 'none',
  }),
  inputField: {
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    border: 'none',
    direction: 'rtl'
  },
  textareaField: { // New textarea styling
    margin: '5px 0',
    padding: '5px',
    width: '60%',
    minHeight: '70px',
    border: 'none',
    direction: 'rtl',
    resize: 'none'
  },
  containerHeader: {
    display: 'flex',
    alignItems: 'center',
    maxWidth:'95%'
  },
  containerTitle:{
    maxWidth:'90%'
  },
  inputCheckbox: {
    width: '60%',
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  cardList: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    borderBottom: '1px solid #eeeeee96',
  },
  marginLeft5: {
    marginLeft: '5px',
  },
  marginLeft10: {
    marginLeft: '10px',
  },
  datepickerField: {
    margin: '5px 0',
    width: '60%',
  
  },
};
