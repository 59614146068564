import React, { useState, useEffect, useCallback, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import EditCard from './EditCard';
import useHttpRequest from '../../hooks/useHttpRequest';
import { mgmApi } from '../../api/apiCalls';
import { MgmSynagogueDataContext } from '../../provider/mgmSynagogueDataProvider';
import { Keyboard } from '@capacitor/keyboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faSpinner } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Capacitor } from '@capacitor/core';

/**
 * פונקציית עזר שמבצעת Reorder של המערך לאחר גרירה ושחרור
 * ומעדכנת את השדה index לפי המיקום החדש.
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result.map((item, idx) => ({
    ...item,
    index: idx + 1,
  }));
};

/**
 * פונקציה לרה-אינדוקס (Reindex) של מערך כאשר יש שינוי כגון מחיקה/הוספה
 * מחזירה מערך חדש בו הפריטים ממופים עם index רציף.
 */
const reindex = (items) => {
  return items.map((item, idx) => ({
    ...item,
    index: idx + 1,
  }));
};

const EditCards = ({
  displayCategory,
  setDisplayCategory,
  cardData,
  headerFields,
  newCard,
  collectionName,
  cardConfig,
  synagogueID,
  showAddAndRemoveButton = true,
  openAlways = false,
  editCategory = false,
}) => {
  const [data, setData] = useState([]);
  const [deletedCardIds, setDeletedCardIds] = useState([]);

  const { performRequest: createUpdateDocuments } = useHttpRequest(mgmApi.createUpdateDocuments);
  const { UpdateContext, hasChanges, setHasChanges } = useContext(MgmSynagogueDataContext);

  const [isSaving, setIsSaving] = useState(false);
  const [isChangeCategory, setIsChangeCategory] = useState(false);

  const [openCardId, setOpenCardId] = useState(null);
  const isWeb = Capacitor.getPlatform() === 'web'; 
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);


  useEffect(() => {
    if (Array.isArray(cardData)) {
      // מיון התחלתי
      const sortedData = [...cardData].sort((a, b) => (a.index || 0) - (b.index || 0));
      setData(sortedData);
    }
    setHasChanges(false);
    setIsChangeCategory(false);
  }, [cardData]);


  useEffect(() => {
    if (!isWeb) {
      // האזנה לשינויים במצב המקלדת (רק אם לא ב-WEB)
      Keyboard.addListener('keyboardWillShow', (info) => {
        setIsKeyboardOpen(true); // המקלדת פתוחה

      });

      Keyboard.addListener('keyboardWillHide', () => {
        setIsKeyboardOpen(false); // המקלדת פתוחה

      });

      return () => {
        // ניקוי האזנה
        Keyboard.removeAllListeners();
      };
    }
  }, [isWeb]);

  const handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      if (result.destination.index === result.source.index) return;

      // Reorder הפריטים
      let reordered = reorder(data, result.source.index, result.destination.index);

      // מסמנים את הפריטים ששינו את ה־index כ־hasChanged = true
      // (למעשה אפשר לסמן את כולם, כי לכולם ה־index זז, אבל אפשר גם ממוקד)
      reordered = reordered.map((item) => ({ ...item, hasChanged: true }));

      setData(reordered);
      setHasChanges(true);
    },
    [data]
  );

  /**
   * טיפול בשינוי בשדות (טקסט וכו').
   */
  const handleInputChange = useCallback((cardId, key, value) => {
    console.log("Sfsdf")
    if (cardConfig[key]?.inputType === 'hebrewDate') {
      // שמירת הנתונים בצורה מובנית
      const updates = {
        englishDate: value.gregorianDate,
        hebrewDay: value.hebrewDate.day,
        hebrewMonth: value.hebrewDate.month,
        hebrewYear: value.hebrewDate.year,
        jewishDateStrHebrew: value.jewishDateStrHebrew
      };

      setData(prevData =>
        prevData.map(item =>
          item.id === cardId ?
            { ...item, ...updates, hasChanged: true } :
            item
        )
      );
    } else {
      // לוגיקה רגילה לעדכון שדות אחרים
      setData(prevData =>
        prevData.map(item =>
          item.id === cardId ?
            { ...item, [key]: value, hasChanged: true } :
            item
        )
      );
    }
    setHasChanges(true);


  }, []);



  const handleDeleteCard = (cardId) => {

    setDeletedCardIds((prevIds) => [...prevIds, cardId]);

    setData((prevData) => prevData.filter((item) => item.id !== cardId));
    setHasChanges(true);
  };

  const handleSave = () => {
    if (isSaving) return;
    setIsSaving(true);

    const changedItems = data.filter((item) => item.hasChanged || deletedCardIds.includes(item.id));  // גם מחוקי גם משתנים
    const deletedCards = deletedCardIds.map((id) => ({ id, isDeleted: true }));

    const combinedData = [...changedItems, ...deletedCards];
    console.log("🚀 ~ handleSave ~ combinedData:", combinedData);

    const combinedData1 = [
      changedItems.length > 0 && {
        type: "changed",
        items: changedItems.map((item) => ({
          id: item.id,
          updateDate: new Date(Date.now() - 5000).toISOString(), // חסר 2000 מילישניות (שתי שניות)
        })),
      },
      deletedCards.length > 0 && {
        type: "deleted",
        items: deletedCards.map((item) => ({
          id: item.id,
          updateDate: new Date(Date.now() - 5000).toISOString(), // חסר 2000 מילישניות (שתי שניות)
          isDeleted: true,
        })),
      },
    ].filter(Boolean);
    console.log("🚀 ~ handleSave ~ combinedData1:", combinedData1)

    var newOutdatedFields = combinedData1.map((group) => ({
      collectionName: collectionName,
      updateDate: new Date(Date.now() - 5000).toISOString(), // חסר 2000 מילישניות (שתי שניות)
      isDeleted: group.type === "deleted",
    }));
    console.log("🚀 ~ handleSave ~ newOutdatedFields:", newOutdatedFields)



    if (isChangeCategory && editCategory) {

      createUpdateDocuments([displayCategory], "synagogueCategoriesPrayers", synagogueID);

      newOutdatedFields.push({
        collectionName: "synagogueCategoriesPrayers",
        updateDate: new Date(Date.now() - 5000).toISOString(),
        isDeleted: false,
      });

    }


    createUpdateDocuments(combinedData, collectionName, synagogueID)
      .then(() => {
        Swal.fire({
          title: 'נשמר בהצלחה',
          icon: 'success',
          timer: 3000,  
         showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        Swal.fire({
          title: 'שגיאה בשמירה',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      })
      .finally(() => {
        // עדכון ב־context


        // עדכון ב־context
        UpdateContext(synagogueID, newOutdatedFields);
        setIsSaving(false);
        setHasChanges(false);

      });
    // setIsSaving(false);
    // setHasChanges(false);

  };
  /**
   * הוספת קלף חדש (index = המקסימלי + 1), וסימון כ־hasChanged
   */
  const handleAddCard = () => {
    // מוצאים אינדקס מרבי שקיים כיום
    const maxIndex = data.reduce(
      (max, item) => (item.index > max ? item.index : max),
      0
    );

    // הכנת קלף חדש
    const cardWithId = {
      ...newCard,
      id: Math.random().toString(36).substr(2, 9),
      index: maxIndex + 1,
      hasChanged: true,
    };

    setData((prevData) => [...prevData, cardWithId]);
    setOpenCardId(cardWithId.id);
    setHasChanges(true);

    // גלילה למטה לראות את הקלף החדש
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth',
      });
    }, 0);
  };

  /**
   * פתיחה/סגירה של קלף
   */
  const toggleCard = useCallback((cardId) => {
    setOpenCardId((prevId) => (prevId === cardId ? null : cardId));
  }, []);

  return (
    <div>
      {editCategory && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}
        >
          <div style={styles.editTitle} className="form-floating">
            <input
              type="text"
              className="form-control"
              style={styles.editTitleInput}
              value={displayCategory.value}
              onChange={(e) => {
                setIsChangeCategory(true);
                setHasChanges(true);
                setDisplayCategory((prev) => ({
                  ...prev,
                  value: e.target.value,
                }));
              }}
            />
            <label style={styles.editTitleLabel}>כותרת לתצוגה על המסך</label>
          </div>

          <div style={styles.inputCheckbox} className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              onClick={(e) => e.stopPropagation()}
              checked={displayCategory.display}
              onChange={(e) => {
                setIsChangeCategory(true);
                setHasChanges(true);
                setDisplayCategory((prev) => ({
                  ...prev,
                  display: e.target.checked,
                }));
              }}
            />
          </div>
        </div>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="cards">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={styles.cardContainer}
            >
              {data.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                >
                  {(draggableProvided) => (
                    <div
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                    >
                      <EditCard
                        item={item}
                        isOpen={openCardId === item.id}
                        toggleCard={toggleCard}
                        onChange={handleInputChange}
                        onDelete={handleDeleteCard}
                        headerFields={headerFields}
                        showAddAndRemoveButton={showAddAndRemoveButton}
                        cardConfig={cardConfig}
                        openAlways={openAlways}
                        disabled={displayCategory?.display}

                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        style={{
          ...styles.saveButton,
          backgroundColor: isSaving || !hasChanges ? '#ccc' : 'rgb(19, 96, 161)',
          position: isKeyboardOpen ? 'static' : 'fixed', 
          cursor: isSaving || !hasChanges ? 'not-allowed' : 'pointer',
        }}
        onClick={handleSave}
        disabled={isSaving || !hasChanges}
      >
        {isSaving ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          <FontAwesomeIcon icon={faSave} />
        )}
        <span style={styles.saveText}>שמירה</span>
      </button>

      {showAddAndRemoveButton && (
        <button style={{
          ...styles.addButton,
          position: isKeyboardOpen ? 'static' : 'fixed', 
        }} onClick={handleAddCard}>
          <FontAwesomeIcon icon={faPlus} />
          <span style={{ marginRight: '5px' }}>הוספה</span>
        </button>
      )}
    </div>
  );
};

export default EditCards;

const styles = {
  cardContainer: {
    // עיצוב לרשימת הקלפים
  },
  editTitle: {
    display: 'flex',
    flexDirection: 'row-reverse',
    fontSize: '1.2em',
    padding: '8px 0 8px 20px',
    direction: 'rtl',
    width: '40%',
    minWidth: '250px',
  },
  editTitleInput: {
    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 2px 4px',
    borderRadius: '5px',
  },
  editTitleLabel: {
    right: 0,
    top: '2px',
    transform: 'scale(0.85) translateY(-0.5rem) translateX(2rem)',
    paddingBottom: 0,
    paddingRight: 0,
  },
  inputCheckbox: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  saveButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    color: '#fff',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 2px',
    minWidth: '50px',
    paddingInline: '16px',
    padding: '10px',
    fontSize: '20px',

  },
  saveText: {
    marginLeft: '5px',
  },
  addButton: {
    position: 'fixed',
    bottom: '20px',
    left: '105px',
    backgroundColor: 'rgb(19, 96, 161)',
    color: '#fff',
    border: 'none',
    borderRadius: '50px',
    cursor: 'pointer',
    margin: '0 15px',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 2px',
    minWidth: '50px',
    paddingInline: '16px',
    padding: '10px',
    fontSize: '20px',
 
  },
};
