import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import './App.css';
import LoginStack, { LoginStackName } from './navigation/login/LoginStack';
import { App as CapacitorApp } from '@capacitor/app';
import MainStack, { MainStackName } from './navigation/main/MainStack';
import UserDashboardStack, { UserDashboardStackName } from './navigation/dashboard/UserDashboardStack';
import MgmStack, { MgmStackName } from './navigation/mgm/MgmStack';
import TermsAndConditions, { TermsStackName } from './pages/TermsAndConditions';
import About, { AboutName } from './pages/About';
import MgmDashboardStack, { MgmDashboardStackName } from './navigation/dashboard/MgmDashboardStack';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { colors } from './styles/colors';
import { VersionUpdatePopup } from './componnents/shared/VersionUpdatePopup';
import { realTimeScreenApi } from './api/apiCalls';
import useAutoHttpRequest from './hooks/useAutoHttpRequest';
import { MgmSynagogueDataContext } from './provider/mgmSynagogueDataProvider';
import { useNavigate } from 'react-router-dom';
import { Keyboard } from '@capacitor/keyboard';


import Swal from 'sweetalert2';

const STACK_SUFFIX = "/*";

function App() {


  console.log = function () { };
  console.error = function () { };
  console.warn = function () { };
  console.info = function () { };
  console.debug = function () { };

  const { result: latestVersion } = useAutoHttpRequest(realTimeScreenApi.getLatestVersion);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const { hasChanges,setHasChanges } = useContext(MgmSynagogueDataContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (Capacitor.isPluginAvailable('StatusBar')) {
      StatusBar.setBackgroundColor({
        color: colors.white,
      });
    }
  }, []);

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      CapacitorApp.getInfo().then(info => {
        setCurrentVersion(info.version);
      });
    }
  }, []);

  useEffect(() => {
    if (latestVersion && currentVersion) {
      if (latestVersion.data) {
        if (currentVersion !== latestVersion.data) {
          setShowUpdatePopup(true);
        }
      }

    }
  }, [latestVersion, currentVersion]);



  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      const backButtonListener = CapacitorApp.addListener('backButton', (event) => {
        const currentPath = window.location.pathname;
        if (currentPath.includes(MainStackName)) {
          CapacitorApp.exitApp();
        }
        if (window.history.length > 1) {
          if (hasChanges) {
            Swal.fire({
              title: 'יש שינויים שלא נשמרו',
              text: '?האם אתה בטוח שברצונך לצאת מבלי לשמור',
              showCancelButton: true,
              confirmButtonText: 'כן, צא',
              cancelButtonText: 'לא, חזור לעריכה',
              focusCancel: true,      
              customClass: {
               cancelButton: 'swal-cancel-button', // מחלקה מותאמת לכפתור הביטול
               confirmButton: 'swal-confirm-button' // מחלקה מותאמת לכפתור האישור
             }
            }).then((result) => {
              if (result.isConfirmed) {
                setHasChanges(false)
                navigate(-1);

              }
            });
          } else {
            setHasChanges(false)
            navigate(-1);

          }

        } else {
          CapacitorApp.exitApp();
        }
      });
      return () => {
        backButtonListener.remove();
      };
    }
  }, [hasChanges]);

  const handlePopupClose = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.tfilatova.app";
  };

  return (
    <>
      {showUpdatePopup && (
        <VersionUpdatePopup
          onClose={handlePopupClose}
        />
      )}
      <Routes>
        <Route path={MgmStackName + STACK_SUFFIX} element={<MgmStack />} />
        <Route path={UserDashboardStackName + STACK_SUFFIX} element={<UserDashboardStack />} />
        <Route path={MgmDashboardStackName + STACK_SUFFIX} element={<MgmDashboardStack />} />
        <Route path={LoginStackName + STACK_SUFFIX} element={<LoginStack />} />
        <Route path={MainStackName + STACK_SUFFIX} element={<MainStack />} />
        <Route path={TermsStackName} element={<TermsAndConditions />} />
        <Route path={AboutName} element={<About />} />
        <Route path="/*" element={<Navigate to={MainStackName} replace />} />
      </Routes>

    </>
  );
}

export default App;
