import React from 'react';
import BackButton from '../componnents/shared/BackButton';
export const TermsStackName = "/termsAndConditions";

const TermsAndConditions = () => {
  return (
    <div style={styles.container}>
              <BackButton title="תנאים והגבלות" />

      <div style={{ ...styles.contentContainer, direction: 'rtl', textAlign: 'right' }}>

      {/* <h1>תנאים והגבלות</h1> */}

      <h2>הקדמה</h2>
      <p>
        ברוכים הבאים ל-תפילה טובה! תנאים וההגבלות הללו מגדירים את הכללים והתקנות לשימוש באתר שלנו, הנמצא בכתובת 
        <a href="https://tfilatova.com" target="_blank" rel="noopener noreferrer"> https://tfilatova.com</a>.
      </p>
      <p>
        על ידי גישה לאתר זה, אנו מניחים שאתה מקבל את תנאים וההגבלות הללו. אל תמשיך להשתמש באתר 
        <strong> תפילה טובה</strong> אם אינך מסכים לכל התנאים וההגבלות המופיעות בדף זה.
      </p>

      <h2>הגדרות</h2>
      <ul>
        <li><strong>"האתר"</strong>: מתייחס לאתר האינטרנט <strong>תפילה טובה</strong>.</li>
        <li><strong>"משתמש"</strong>: מתייחס לאדם הניגש או משתמש באתר.</li>
        <li><strong>"אנחנו/שלנו"</strong>: מתייחסים ל-<strong>תפילה טובה</strong>.</li>
      </ul>

      <h2>רישיון</h2>
      <p>
        אלא אם צוין אחרת, <strong>תפילה טובה</strong> מחזיקה בזכויות הקניין הרוחני של כל החומרים באתר זה. כל הזכויות שמורות.
        אתה רשאי לגשת לאתר זה לשימוש אישי בכפוף למגבלות המפורטות בתנאים וההגבלות הללו.
      </p>

      <h2>איסורים</h2>
      <p>השימוש באתר זה אסור ב:</p>
      <ul>
        <li>שימוש בלתי מורשה בתוכן לצרכים מסחריים.</li>
        <li>שינויים או העתקה של כל חומר מהאתר ללא רשות מראש.</li>
        <li>פעילות המפרה זכויות קניין רוחני או זכויות פרטיות.</li>
      </ul>

      <h2>שינויים</h2>
      <p>
        אנו שומרים לעצמנו את הזכות לשנות או לעדכן את תנאים וההגבלות הללו בכל עת, ללא הודעה מוקדמת. המשך השימוש באתר מהווה את הסכמתך לשינויים.
      </p>

      <h2>יצירת קשר</h2>
      <p>לשאלות נוספות, אנא פנה אלינו בכתובת: <a href="mailto:tfilatovaweb@gmail.com">tfilatovaweb@gmail.com</a>.</p>
    </div>    
      </div>

  );
};

const styles = {
  container: {
      padding: "20px",
  },
  contentContainer: {
      margin: "20px auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }

};


export default TermsAndConditions;
