import { ApiUtils } from "./ApiUtils";


export const GET_SYNGOGUE_DATA_URL = "synagogue/mgm/getSynagogueAllDataByPass";
export const DELETE_SYNGOGUE = "synagogue/mgm/deleteAccount";

export const CREATE_UPDATE_DOCUMENTS = "synagogue/mgm/createUpdateDocuments?";
export const GET_UPDATED_RECORDS = "synagogue/mgm/getUpdatedRecords?";
export const GET_ZMANIM = "zmanim?cfg=json";
export const GET_SHABBAT = "shabbat?cfg=json";
export const GET_DAILY_PAGE = "hebcal?cfg=json&v=1&F=on&start";

export const GET_SYNGOGUE_DATA_BY_ID = "synagogue/user/getSynagogueDataByID?";

export const POST_SEND_MAIL = "mail/sendMail";



export const loginApi = {
    getSynagogueAllDataByPass: (email,password) => {
    console.log("🚀 ~ password:", password)
    console.log("🚀 ~ email:", email)

        return ApiUtils.get(GET_SYNGOGUE_DATA_URL, {
            email: email,
            password: password
        })
    }
}

export const mgmApi = {
    createUpdateDocuments: (arrObjs,collectionName,synagogueID) => {
        return ApiUtils.post(`${CREATE_UPDATE_DOCUMENTS}collectionName=${collectionName}&synagogueID=${synagogueID}`, arrObjs)
    },

    getUpdatedRecords: (synagogueID,arrObjs) => {

        return ApiUtils.post(`${GET_UPDATED_RECORDS}synagogueID=${synagogueID}`, arrObjs)
    },

    getZmanim: (geonameid) => {
        return ApiUtils.getHwbcal(`${GET_ZMANIM}&geonameid=${geonameid}`)
    },

    getParasha: (geonameid) => {
        return ApiUtils.getHwbcal(`${GET_SHABBAT}&geonameid=${geonameid}&leyning=on`)
    },
    getDailyPage: (date) => {
        return ApiUtils.getHwbcal(`${GET_DAILY_PAGE}=${date}&end=${date}`)
    },
    deleteSynagogue: (synagogueID) => {
    console.log("🚀 ~ synagogueID:", synagogueID)

        const data = { id: synagogueID };
        return ApiUtils.post(DELETE_SYNGOGUE,data)
    }
}

export const userApi={
    getSynagogueDataByID: (synagogueID) => {
        return ApiUtils.get(`${GET_SYNGOGUE_DATA_BY_ID}synagogueID=${synagogueID}`)
    },
}

export const sendMailApi={
    sendMail: (arrObjs) => {
        console.log("🚀 ~ POST_SEND_MAIL:", POST_SEND_MAIL)

        return ApiUtils.post(`${POST_SEND_MAIL}`,arrObjs)
    }
}


export const realTimeApi = {
    getUpdateDates: (synagogueID) => {
       
        return ApiUtils.getRealTime(`${synagogueID}.json`)
    }
}




export const realTimeScreenApi = {
    getSynagogueNames: () => {     
        return ApiUtils.getScreenRealTime(`names.json`)
    },

    getLatestVersion: () => {     
        return ApiUtils.getScreenRealTime(`latest_version.json`)
    }
}