import { useEffect } from "react";
import useHttpRequest from "./useHttpRequest";

const defaultConfigurations = {
    body: {},
    onSuccess: null,
    onFailure: null,
}

export default function useAutoHttpRequest(requestFunction, configurations) {
    const { body, onSuccess, onFailure } = { ...defaultConfigurations, ...configurations };

    const httpRequest = useHttpRequest(requestFunction, onSuccess, onFailure);

    
    useEffect(() => {
        httpRequest.performRequest(...Object.values(body));

        return httpRequest.cancelRequest;
    }, []);

    const isPerforming = httpRequest.isPerforming || !httpRequest?.result?.success;

    return { ...httpRequest, isPerforming };
}