import React from 'react';

export const VersionUpdatePopup = ({  onClose }) => {
  return (
    <>
      <style>{`
        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }

        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }

        .update-popup-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(145deg, rgb(70 113 160), rgb(175 207 237));
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
          backdrop-filter: blur(8px);
        }

        .update-popup-container {
          background: rgba(255, 255, 255, 0.95);
          border-radius: 24px;
          padding: 40px;
          width: 90%;
          max-width: 450px;
          text-align: center;
          box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
          animation: slideIn 0.6s ease-out;
          direction: rtl;
        }

        .update-icon {
          width: 80px;
          height: 80px;
          margin: 0 auto 24px;
          animation: pulse 2s infinite ease-in-out;
        }

        .update-popup-title {
          margin-bottom: 16px;
          font-size: 32px;
          color: #1a1a1a;
          font-weight: bold;
          line-height: 1.3;
        }

        .update-popup-message {
          margin-bottom: 32px;
          font-size: 18px;
          color: #666;
          line-height: 1.6;
        }

        .version-tag {
          display: inline-block;
          background: rgba(76, 110, 245, 0.1);
          color: #4C6EF5;
          padding: 6px 12px;
          border-radius: 12px;
          font-size: 16px;
          font-weight: 600;
          margin: 16px 0;
        }

        .update-popup-button {
          padding: 16px 32px;
          font-size: 18px;
          background: linear-gradient(145deg, rgb(87 137 192), rgb(50 84 121));
          color: #fff;
          border: none;
          border-radius: 16px;
          cursor: pointer;
          transition: all 0.3s ease;
          font-weight: 600;
          width: 100%;
          max-width: 300px;
          box-shadow: 0 4px 12px rgba(76, 110, 245, 0.3);
        }

        .update-popup-button:hover {
          transform: translateY(-2px);
          box-shadow: 0 6px 16px rgba(76, 110, 245, 0.4);
        }

        .benefits-list {
          text-align: right;
          margin: 24px 0;
          color: #555;
          font-size: 16px;
          line-height: 1.8;
        }

        .benefit-item {
          display: flex;
          align-items: center;
          margin-bottom: 12px;
        }

        .benefit-icon {
          margin-left: 12px;
          color: #4C6EF5;
        }
      `}</style>
      <div className="update-popup-overlay">
        <div className="update-popup-container">
          <svg className="update-icon" viewBox="0 0 24 24" fill="none" stroke="#4C6EF5" strokeWidth="2">
            <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48l2.83-2.83" strokeLinecap="round"/>
          </svg>
          
          <h2 className="update-popup-title">יש לנו חדשות טובות  🎉</h2>
          
          <div className="version-tag">גרסה {'חדשה'} זמינה</div>
          
          <p className="update-popup-message">
            שדרגנו את האפליקציה שלנו עם תכונות חדשות ושיפורים מעולים
          </p>

          {/* <div className="benefits-list">
            <div className="benefit-item">
              <span className="benefit-icon">✨</span>
              <span>ביצועים משופרים ומהירות גבוהה יותר</span>
            </div>
            <div className="benefit-item">
              <span className="benefit-icon">🛡️</span>
              <span>שיפורי אבטחה ויציבות</span>
            </div>
            <div className="benefit-item">
              <span className="benefit-icon">🎯</span>
              <span>תכונות חדשות שביקשתם</span>
            </div>
          </div> */}

          <button className="update-popup-button" onClick={onClose}>
            עדכן עכשיו לגרסה החדשה
          </button>
        </div>
      </div>
    </>
  );
};