import React, { useState, useCallback, useEffect, useContext } from 'react';
import useHttpRequest from '../../hooks/useHttpRequest';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mgmApi, sendMailApi } from '../../api/apiCalls';
import Swal from 'sweetalert2';
import {
  faUser,
  faEnvelope,
  faPhone,
  faPlaceOfWorship,
  faCity,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";


export default function SignUpForm() {
  const { performRequest: createUpdateDocuments } = useHttpRequest(mgmApi.createUpdateDocuments);
  const { performRequest: sendMail } = useHttpRequest(sendMailApi.sendMail);

  const [formData, setFormData] = useState({
    userName: "",
    email: "",
    phone: "",
    synagogueName: "",
    street: "",
    city: "",
    id: Math.random().toString(36).substr(2, 9),
  });
  const [formError, setFormError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!isFormValid()) {
      setFormError("אנא מלא את כל השדות הנדרשים");
      return;
    }
  
    setFormError(""); // איפוס הודעת שגיאה
  
    const arrayObject = {
      "subject": "לקוח חדש רוצה להצטרף לתפילה טובה! 🎉",
      "text": `התקבלה בקשת הרשמה חדשה למערכת.  
      להלן פרטי המבקש:  
    
      **שם:** ${formData.userName}  
      **אימייל:** ${formData.email}  
      **טלפון:** ${formData.phone}  
      **שם בית הכנסת:** ${formData.synagogueName}  
      **כתובת:** ${formData.street}, ${formData.city}  
    
      אנא בצעו את הבדיקות הנדרשות והשלימו את תהליך הרישום בהתאם.  
    
      תודה,  
      המערכת`
    };
    
  
    // הצגת חלון טעינה
    Swal.fire({
      title: "...שולח נתונים",
      text: "אנא המתן",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  
    // קריאה ל-API לשמירת הנתונים
    createUpdateDocuments([formData], "onboarding", 0)
      .then(() => {
        return sendMail(arrayObject);
      })
      .then(() => {
        // הצלחה - הצגת הודעת הצלחה
        Swal.fire({
          title: "הרשמה הושלמה בהצלחה!",
          text: "ניצור איתך קשר בהקדם",
          icon: "success",
          confirmButtonText: "OK",
        });
  
        console.log("✅ Form successfully submitted:", formData);
      })
      .catch((error) => {
        // טיפול בשגיאה - הצגת הודעה מתאימה
        console.error("error submitting form:", error);
        Swal.fire({
          title: "שגיאה בשליחת הנתונים",
          text: "אנא נסה שוב מאוחר יותר",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  };
  
  // פונקציה לבדיקת תקינות הטופס
  const isFormValid = () => {
    const { userName, email, phone, synagogueName, street, city } = formData;
    return userName && email && phone && synagogueName && street && city;
  };
  
  

  return (
    <form action="#" className="sign-up-form" onSubmit={handleSubmit}>
      <h2 className="title">הרשמה</h2>
      <h6 className="sub-title">הכנס פרטים ואנו נחזור אליך בהקדם</h6>


      <div className="input-field">
        <FontAwesomeIcon icon={faUser} />
        <input
          type="text"
          name="userName"
          placeholder="שם הגבאי"
          value={formData.userName}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faEnvelope} />
        <input
          type="email"
          name="email"
          placeholder="מייל"
          value={formData.email}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faPhone} />
        <input
          type="tel"
          name="phone"
          pattern="0[5][0-9]{8}"
          placeholder="טלפון ליצירת קשר"
          value={formData.phone}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faPlaceOfWorship} />
        <input
          type="text"
          name="synagogueName"
          placeholder="שם בית הכנסת"
          value={formData.synagogueName}
          onChange={handleChange}
        />
      </div>

      <div className="input-field">
        <FontAwesomeIcon icon={faMapMarkerAlt} />
        <input
          type="text"
          name="street"
          placeholder="רחוב"
          value={formData.street}
          onChange={handleChange}
        />
      </div>
      <div className="input-field">
        <FontAwesomeIcon icon={faCity} />
        <input
          type="text"
          name="city"
          placeholder="עיר"
          value={formData.city}
          onChange={handleChange}
        />
      </div>
      {formError && <p className="error-message">{formError}</p>}

      <input type="submit" className="btn" value="שלח פרטים" />
    </form>
  );
}
